<template>
  <div class="d-flex h-100 w-100">
    <div class="he-ui-selected-sensor-section d-flex flex-column justify-content-between h-100">
      <SensorInPhase
        v-for="phase in phases"
        :key="phase"
        :phase="phase"/>
    </div>
    <div class="he-ui-sensors-list bg-white h-100">
      <span class="he-ui-sensors-list-title"> {{ $t('main.sensor_list')}} </span>
      <div class="he-ui-sensors-wrapper">
        <div
          v-for="image in images"
          :key="image"
          :id="image.id"
          draggable="true"
          :ondragstart="drag"
          class="he-ui-sensor-img-wrapper">
          <img :id="image.id" :src="image.src" :alt="image.alt" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SensorInPhase from '@/components/sensor/SensorInPhase'

export default {
  components: {
    SensorInPhase
  },
  data () {
    return {
      images: [
        { id: 'image1', src: require('../../assets/sensor.png'), alt: 'sensor_img' },
        { id: 'image2', src: require('../../assets/sensor.png'), alt: 'sensor_img' },
        { id: 'image3', src: require('../../assets/sensor_2.png'), alt: 'sensor_img' },
        { id: 'image4', src: require('../../assets/sensor_2.png'), alt: 'sensor_img' }
      ],
      phases: [
        {
          id: 1,
          src: require('../../assets/statistics/admision.svg'),
          alt: 'admission',
          tag: this.$t('main.admission'),
          active: true,
          onClick: this.addSensor
        },
        {
          id: 2,
          src: require('../../assets/statistics/quirofano.svg'),
          alt: 'operating_room',
          tag: this.$t('main.operating_room'),
          active: true,
          onClick: this.addSensor
        },
        {
          id: 3,
          src: require('../../assets/statistics/postoperatorio.svg'),
          alt: 'posoperative',
          tag: this.$t('main.postoperative'),
          active: true,
          onClick: this.addSensor
        }
      ]
    }
  },
  methods: {
    addSensor (id) {
      console.log(id)
    },
    drag (ev) {
      ev.dataTransfer.setData('text', ev.target.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-selected-sensor-section {
  width: 60%;
}
.he-ui-sensors-list {
  width: 40%;
  border-radius: 1%;
  padding: 4% 5%;

  .he-ui-sensors-list-title {
    font-size: 2.5rem;
    font-weight: bold;
    text-transform: uppercase;
  }
}
.he-ui-sensors-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr ;
  gap: 2%;
}
.he-ui-sensor-img-wrapper {
  border: 1px solid blue;
  background: #E2F4F9;
  cursor: move;
}
</style>
