<template>
  <div class="he-ui-sensors-in-phase d-flex align-items-center">
    <Phase
      :data="phase"
      :showTimeCircle="false"
      showButton
      buttonIcon="add"
      buttonText="main.add"
      :onClickHandler="() => { phase.onClick(phase.id) }" />
    <div
      class="w-100 h-100 p-4 d-flex align-items-center"
      :ondrop="drop"
      :ondragover="allowDrop" />
  </div>
</template>

<script>
import Phase from '@/components/Phase'

export default {
  components: {
    Phase
  },
  props: {
    phase: {
      type: Object,
      default: () => null
    }
  },
  methods: {
    drop (event) {
      event.preventDefault()
      const data = event.dataTransfer.getData('text')
      event.target.appendChild(document.getElementById(data))
    },
    allowDrop (event) {
      event.preventDefault()
    }
  }
}
</script>

<style lang="scss">
.he-ui-sensors-in-phase {
  .he-ui-statistics-image-wrapper {
    width: 8.5rem;
    height: 8.5rem;
    margin-bottom: 2rem;
    .he-ui-statistics-image-tag {
      font-size: 1rem;
    }
  }
  .he-ui-overlay-content-button {
    font-size: .9rem;
    min-width: 6rem;
    &.he-ui-main-button-rounded {
      padding: inherit;
    }
  }
}
</style>
